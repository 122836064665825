import React from "react";

import "./style.scss";

const CareerFilter = ({logo, onClick, status}) => (
    <button 
        className={`career-insegna rounded ie ${status ? "selected" : ""}`} 
        onClick={onClick} 
        onKeyDown={onClick} 
        alt={`seleziona l'insegna`} 
        aria-label={`seleziona l'insegna`} 
        style={{backgroundImage: `url(${logo})`}}
    ></button>
)

export default CareerFilter
