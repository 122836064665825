import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import language_config from "../../language-config";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Slider from "../components/Slider";
import CareerFilter from "../components/CareerFilter";
import CardCareer from "../components/Cards/CardCareer";
import Title from "../components/Title";

import insegnePamPanoramaLogo from "../static/logos/logo-pam-panorama.png";
import insegnePamLocalLogo from "../static/logos/logo-local.png";
import insegneDiscountLogo from "../static/logos/logo-ins.png";
import insegneFranchisingLogo from "../static/logos/logo-franchising.png";
import insegneCityLogo from "../static/logos/logo-city.png";
import insegneGruppoPamLogo from "../static/logos/logo-gruppo.png";

import careersInfo1 from "../images/careers-info-1.jpg";
import careersInfo2 from "../images/careers-info-2.jpg";
import careersInfo3 from "../images/careers-info-3.jpg";
import openCandIcon from "../images/careers-cand.svg";

import iconFirst from "../images/career-crediamo.png";
import iconSecond from "../images/career-fare.png";
import iconThird from "../images/career-offriamo.png";

import banner from "../images/header-careers.jpg";

import "../utils/general.scss";
import "../utils/page-careers.scss";

const Careers = ({ location, pageContext }) => {
    let [path, setPath] = useState('/');

    useEffect(() => {
        let newPath = ''
        if (typeof window !== 'undefined') {
            newPath = ((language_config[window.localStorage.language].is_default ? '' : ('/' + window.localStorage.language)) + '/')
        } else {
            newPath = '/'
        }
        setPath(newPath)
    }, []);


    const scrollTo = (section) => {
        window.scrollTo({ 
            top: window.document.querySelector(section).offsetTop, 
            behavior: 'smooth'
        })
    }

    return (
        <Layout pageContext={pageContext}>
            <SEO title={pageContext.localeResources['menu.careers']} description="Scopri le posizioni attualmente aperte e candidati">
                {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
                <meta http-equiv="refresh" content={`0;URL=https://lavoraconnoi.gruppopam.it/`}/>
            </SEO>

            <div className="container">
                <Slider 
                    content={[{
                        text: pageContext.localeResources['careers.slide'], 
                        image: banner
                    }]} 
                    style={{maxHeight: "60vh"}} 
                />
            </div>

            <section className="section-career" style={{paddingBottom: "80px"}}>
                <div className="container section">
                    <div className="boxes ie" style={{marginBottom: 40}}>
                        <img src={iconFirst}/>
                        <h5 className="subtitle">{pageContext.localeResources['careers.boxes.first.title']}</h5>
                        <a className="btn green" onClick={() => scrollTo("#firstBox")}>{pageContext.localeResources['cta.readMore']}</a>
                    </div>
                    <div className="boxes ie" style={{marginBottom: 40}}>
                        <img src={iconSecond}/>
                        <h5 className="subtitle">{pageContext.localeResources['careers.boxes.second.title']}</h5>
                        <a className="btn green" onClick={() => scrollTo("#secondBox")}>{pageContext.localeResources['cta.readMore']}</a>
                    </div>
                    <div className="boxes ie" style={{marginBottom: 40}}>
                        <img src={iconThird}/>
                        <h5 className="subtitle">{pageContext.localeResources['careers.boxes.third.title']}</h5>
                        <a className="btn green" onClick={() => scrollTo("#thirdBox")}>{pageContext.localeResources['cta.readMore']}</a>
                    </div>
                </div>
            </section>

            {/* Intro */}
            <section className="section-career section-positions section-text">
                <div className="container section">
                    <h5 className="subtitle yellow ie">{pageContext.localeResources['careers.mainDescription.subtitle']}</h5>
                    <Title title={`${pageContext.localeResources['careers.mainDescription.title']}`} />
          
                    <p 
                        className="text ie" 
                        data-aos="fade-right" 
                        data-aos-offset="200" 
                        dangerouslySetInnerHTML={{ __html: pageContext.localeResources['careers.mainDescription.text1'] }}
                    ></p>
                    <p 
                        className="text ie" 
                        data-aos="fade-left" 
                        data-aos-offset="200"
                        dangerouslySetInnerHTML={{ __html: pageContext.localeResources['careers.mainDescription.text2'] }}
                    ></p>
                </div>
            </section>


            {/* Links */}
            <section className="section-positions">
                <div className="container section">
                    <CardCareer
                        href="mailto:infojob@gruppopam.it"
                        logoInsegna={openCandIcon}
                        date={pageContext.localeResources['careers.sendCurriculum.subtitle']}
                        position={pageContext.localeResources['careers.sendCurriculum.title']}
                    />
                </div>
            </section>

            {/* Boxes */}
            <section className="section-half colorful-bg" id="firstBox">
                <div className="info" data-aos="fade-right">
                    <h2>{pageContext.localeResources['careers.boxes.first.title']}</h2>
                    <p dangerouslySetInnerHTML={{__html: pageContext.localeResources['careers.boxes.first.text']}}></p>
                    <Link to={`${path}in-cosa-crediamo`} className="btn green">{pageContext.localeResources['cta.foundOut']}</Link>
                </div>
                <div className="image" style={{backgroundImage: `url(${careersInfo1})`}} data-aos="fade-left"></div>
            </section>
            <section className="section-half colorful-bg" id="secondBox">
                <div className="image" style={{backgroundImage: `url(${careersInfo2})`}} data-aos="fade-right"></div>
                <div className="info" data-aos="fade-left">
                    <h2>{pageContext.localeResources['careers.boxes.second.title']}</h2>
                    <p dangerouslySetInnerHTML={{__html: pageContext.localeResources['careers.boxes.second.text']}}></p>
                </div>
            </section>
            <section className="section-half colorful-bg" id="thirdBox">
                <div className="info" data-aos="fade-right">
                    <h2>{pageContext.localeResources['careers.boxes.third.title']}</h2>
                    <p dangerouslySetInnerHTML={{__html: pageContext.localeResources['careers.boxes.third.text']}}></p>
                    <Link to={`${path}benefits`} className="btn green">{pageContext.localeResources['cta.foundOut']}</Link>
                </div>
                <div className="image" style={{backgroundImage: `url(${careersInfo3})`}} data-aos="fade-left"></div>
            </section>
        </Layout>
    )
}

export default Careers
